<template>
  <div class="inner-container">
    <section class="banner-container-nd">
      <div class="banner"></div>
      <div class="banner-content">
        <div class="cards-container">
          <v-card
            v-for="(card, index) in cards"
            :key="index"
            class="hover-card"
            @click="handleCardClick()"
          >
            <div class="text-center title-color my-3">
              {{ card.title }}
            </div>
          </v-card>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    data() {
      return {
        data: {
    "brouillon": 1,
    "type_devis": "",
    "entreprise_id": "1",
    "modele_id": "14",
    "groupes": [
      {
        "type_piece_id": 116,
        "piece": "piece1",
        "type_traveaux_id": 1,
        "traveaux": "travaux 1",
        "sous_traitant_id": null,
        "produits": [
         ]
      }
    ]
  },
        cards: [
          { title: "Libre", route: "/devis/manuel" },

        ],
      };
    },
    components: {},
    computed: {
      ...mapGetters(["getTabs", "getOnlineUser"]),
      isSuperAdmin() {
        return this.getOnlineUser.role == "user.super-admin";
      },
      isentrepriseAdmin() {
        return this.getOnlineUser.role == "user.entreprise-admin";
      },
      isCommercial() {
        return this.getOnlineUser.role == "user.commercial-entreprise";
      },
      isAssistant() {
        return this.getOnlineUser.role == "user.assistant";
      },
    },
    methods: {
  //   ...mapActions(['store_devis']),
  //     async createDevis(typeDevis) {
       
  //         this.data.type_devis=typeDevis
         
  
  //         const response = await this.store_devis(this.data);
      
  //         this.id = response.data?.devis?.id;
  //         if(response.data?.devis?.type_devis==="Libre"){
  //   this.$router.push(`/devis/Libre/${this.id}`);
  // }
    
       
  //     },
     
      handleCardClick() {
        this.$router.push(`/devis/manuel`);
          // this.createDevis(card.title);
      
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
    .inner-container {
      padding: 0 !important;
      width: 100%;
      margin-top: 50px;
    }
     @media only screen and (min-width: 810px) { 
    .banner-container-nd {
      position: relative;
      height: calc(100vh - 50px); 
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      width: 100%; 
    }
     } 
    
    .banner-content {
      @media only screen and (max-width: 900px) {
     
        padding: 8px;
        width: 100%;
      }
      width: 100%;
      text-align: center;
    
      .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px; 
      }
    
      .v-card {
        flex: 1 1 calc(25% - 20px); 
        max-width: calc(25% - 20px); 
        margin: 10px; 
        border-radius: 8px; 
        background-color: #24316d;
        font-size: 16px; 
        font-weight: 600;
        padding: 20px;
      }
    
      @media only screen and (max-width: 810px) {
         .banner-container-nd {
      position: relative;
      height: calc(140vh -50px); 
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      width: 100%; 
    } 
        .v-card {
          flex: 1 1 calc(50% - 20px); 
          max-width: calc(50% - 20px); 
        }
      }
      @media only screen and (max-width: 620px) {
         .banner-container-nd {
      position: relative;
      height: calc(200vh); 
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      width: 100%; 
    } 
        .v-card {
          flex: 1 1 calc(100% - 20px);
          max-width: calc(100% - 20px); 
        }
      }
    }
    
    .hover-card {
      color: white;
      transition: box-shadow 0.3s ease;
    }
    
    .hover-card:hover {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2); 
    }
    
    .card-link {
      background-color: #24316d;
      text-decoration: none;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }
    .title-color{
      align-items:center;
      color:white;
    }
    </style>
  